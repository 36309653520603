import React, {useEffect, useState} from 'react';
import {Route, Switch, useLocation, useParams, useRouteMatch} from 'react-router-dom';
import {AppLayout, SideNavigation, Spinner, Box, StatusIndicator} from '@amzn/awsui-components-react';
import {MetadataRecord, MetadataAuditRecords} from '@amzn/f3-excelsior-store-metadata-api/clients/apiforexcelsiorstoremetadata';
import {useGetStoreMetadata, useListMetadataAudits} from '../hooks/use-store-metadata-api';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import StoreMetadataPage from './store-metadata';
import AuditDetailPage from './audit-detail';
import {Notification} from '../navigation/page-layout';
import {translateErrorToReactNode} from '../common';

export default function StoreMetadataLayout({pushNotification}: {pushNotification: (notification: Notification) => void}) {
    const {programRegionId} = useParams<{programRegionId: string}>();
    const {path} = useRouteMatch();
    const location = useLocation();
    const auth = useAuth();

    const [metadataRecord, setMetadataRecord] = useState<MetadataRecord | null>(null);
    const [auditRecords, setAuditRecords] = useState<MetadataAuditRecords>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    const clientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.StoreMetadata);

    const createErrorListener = (header: string) => (error: Error) => {
        setError(error);
        pushNotification({
            type: 'error',
            content: translateErrorToReactNode(error),
            header,
        });
    };

    const {execute: executeGetStoreMetadata} = useGetStoreMetadata(
        clientConfiguration,
        createErrorListener('GetStoreMetadata failed'),
        [auth]
    );

    const {execute: executeListMetadataAudits} = useListMetadataAudits(
        clientConfiguration,
        createErrorListener('ListMetadataAudits failed'),
        [auth]
    );

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const [metadataResponse, auditsResponse] = await Promise.all([
                    executeGetStoreMetadata({programRegionId}),
                    executeListMetadataAudits({programRegionId}),
                ]);

                setMetadataRecord(metadataResponse?.record || null);
                setAuditRecords(auditsResponse?.records || []);
                setError(null);
            } catch (err) {
                setError(err as Error);
            } finally {
                setIsLoading(false);
            }
        };

        if (programRegionId) {
            fetchData();
        }
    }, [programRegionId]);

    const isStoreOverridden = (audits: MetadataAuditRecords): boolean => {
        if (audits.length === 0) return false;
        return audits[0].eventType === 'SUBMIT';
    };

    if (isLoading) {
        return (
            <Box margin="xxl" textAlign="center">
                <Spinner />
            </Box>
        );
    }

    if (error || !metadataRecord) {
        return (
            <Box margin="xxl" textAlign="center">
                <StatusIndicator type="error">Failed to load metadata: {error?.message || 'Store Not Found'}</StatusIndicator>
            </Box>
        );
    }

    return (
        <AppLayout
            content={
                <Switch>
                    <Route
                        exact
                        path={path}
                        render={(props) => (
                            <StoreMetadataPage
                                {...props}
                                pushNotification={pushNotification}
                                metadataRecord={metadataRecord}
                                clientConfiguration={clientConfiguration}
                                isOverriden={isStoreOverridden(auditRecords)}
                            />
                        )}
                    />
                    <Route path={`${path}/audit`} render={(props) => <AuditDetailPage {...props} auditRecords={auditRecords} />} />
                </Switch>
            }
            navigation={
                <SideNavigation
                    header={{
                        href: `/store/${programRegionId}`,
                        text: 'Store Metadata',
                    }}
                    items={[
                        {
                            type: 'link',
                            text: 'Properties',
                            href: `/store/${programRegionId}`,
                        },
                        {
                            type: 'link',
                            text: 'Audit History',
                            href: `/store/${programRegionId}/audit`,
                        },
                    ]}
                    activeHref={location.pathname}
                />
            }
            toolsHide={true}
        />
    );
}
