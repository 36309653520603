import React, {useState, useEffect, useContext, createContext, useRef} from 'react';
import Auth from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';
import {AWSError, Credentials} from 'aws-sdk';
import {useLocalStorage} from './use-local-storage';
import RestAPI from '@aws-amplify/api-rest';
import {Permissions} from '@amzn/f3-excelsior-portal-permissions-api-client';
import {CredentialsOptions} from 'aws-sdk/lib/credentials';
import Analytics, {AWSKinesisFirehoseProvider} from '@aws-amplify/analytics';
import {recordUserEvent} from '../common/portal-analytics';
import {AbeautifulandamazingF3ExcelsiorConfigurationLambdaModel} from '@amzn/f3-excelsior-forecast-configuration-lambda';
import {PortalEventName} from '../common/portal-event-name-enum';

/**
 * This is a perfect use-case for a useAuth hook that enables any component
 * to get the current auth state and re-render if it changes. Rather than
 * have each instance of the useAuth hook fetch the current user, the hook
 * simply calls useContext to get the data from farther up in the component
 * tree. The real magic happens in our <ProvideAuth> component and our useProvideAuth
 * hook which wraps all our authentication methods and then uses React Context to make
 * the current auth object available to all child components that call useAuth
 *
 * https://usehooks.com/useAuth
 *
 * Example:
 * <ProvideAuth>
 *   <!-- any component can do useAuth() to access the data -->
 * </ProvideAuth>
 */
// This is the Auth Context for the Log in user
export interface AuthContext {
    isAuthenticated: boolean;
    // Optional; exist only when login
    authInformation?: AuthInformation;
}

export interface BusinessContext {
    businessId: string;
    country: string;
    flow: string;
    regionId: RegionId;
    configurationData?: ConfigurationData;

    /**
     * @Deprecated
     */
    permissions: UserPermission;
}

export interface UserPermission {
    canManageForecastWorkflows: boolean;
    canUploadMarketingInputs: boolean;
    canUploadBulkOverrides: boolean;
    canViewForecasts: boolean;
    canEditForecasts: boolean;
    canViewAccuracyDashboard: boolean;
    canViewExplainability: boolean;
    canViewConfigurations: boolean;
    canViewStoreMetadata: boolean;
}

export interface ConfigurationData {
    insightsEnabledOnPortal?: boolean;
    modelName?: string;
    forecastBuilderEnabledOnPortal?: boolean;
    baselineWeeksTabEnabledOnForecastBuilder?: boolean;
    regionId: string;
}

// Once authenticated
export interface AuthInformation {
    email: string;
    businessContexts: BusinessContext[];
    getEndpoints: () => Record<RegionId, Record<ServiceResource, ServiceEndpoint>>;

    // present when selected business
    current?: BusinessContext;
    getCurrentServiceEndpoint: (service: ServiceResource) => ServiceEndpoint | any;

    // This will change "current" per input
    switchTo: (businessId: string, country: string, flow: string) => Promise<void>;

    /**
     * New fields used with the Permissions API
     */
    globalScopes?: Record<string, string>;
}

export enum RegionId {
    NA = 'NA',
    EU = 'EU',
    IN = 'IN',
    FE = 'FE',
}

/**
 * Mapping of countries to regionIds. With EU 3P being added to the portal, the user will no
 * longer pick from a region in the business selection page, instead they will pick at the
 * country level (US, FR, DE, etc.). However, all of the EU countries will use the same EU
 * stack for their Excelsior services. This map is used to ensure the correct endpoints are
 * used for whichever country the user selects.
 */
export const COUNTRY_TO_REGION_MAP: {[key: string]: RegionId} = {
    US: RegionId.NA,
    DE: RegionId.EU,
    FR: RegionId.EU,
    ES: RegionId.EU,
    IT: RegionId.EU,
    GB: RegionId.EU,
    IN: RegionId.IN,
    JP: RegionId.FE,
    SG: RegionId.FE,
};

export enum BusinessId {
    AFS = 'afs',
    GO = 'go',
    HUBS = 'hubs',
    UFF = 'uff',
    WFM = 'wfm',
    WFM_INSTORE = 'wfminstore',
    _3P = '3p',
}

export enum Country {
    US = 'US',
    DE = 'DE',
    ES = 'ES',
    FR = 'FR',
    GB = 'GB',
    IT = 'IT',
    IN = 'IN',
    JP = 'JP',
    SG = 'SG',
}

export enum Flow {
    OUTBOUND = 'Outbound',
    INBOUND = 'Inbound',
    INBOUND_REMOVALS = 'Inbound-Removals',
}

export enum ServiceResource {
    ConfigurationView = 'ConfigurationView',
    OrchestratorView = 'OrchestratorView',
    OrchestratorEdit = 'OrchestratorEdit',
    ForecastStoreView = 'ForecastStoreView',
    ForecastStoreEdit = 'ForecastStoreEdit',
    ForecastStoreInsights = 'ForecastStoreInsights',
    MerchantAutomation = 'MerchantAutomation',
    ExplainabilityView = 'ExplainabilityView',
    StoreMetadata = 'StoreMetadata',
}

export interface ServiceEndpoint {
    /**
     * Base URL of API endpoint
     */
    endpoint: string;

    region: string;
    credentials: Credentials;
}

// These are private interfaces
interface SettingsEndpointConfig {
    orchestratorApiEndpoint: string;
    forecastStoreApiEndpoint: string;
    merchantAutomationApiEndpoint: string;
    configurationStoreApiEndpoint: string;
    analyticsServiceApiEndpoint: string;
    storeMetadataApiEndpoint: string;
}

interface Settings {
    stage: string;
    region: string;
    cognitoAppClientId: string;
    cognitoAuthDomain: string;
    cognitoUserPoolId: string;
    cognitoIdentityPoolId: string;
    permissionsApiEndpoint: string;
    [RegionId.NA]: SettingsEndpointConfig;
    [RegionId.EU]: SettingsEndpointConfig;
    [RegionId.IN]: SettingsEndpointConfig;
    [RegionId.FE]: SettingsEndpointConfig;
}

const authContext = createContext({
    isAuthenticated: false,
} as AuthContext);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth(props: {children?: React.ReactNode}) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth} {...props} />;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};
// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const [auth, setAuth] = useState({isAuthenticated: false} as AuthContext);
    // These are the values the user selected on the business selection the last time the user visits the site.
    const {value: businessId, setValue: setBusinessId} = useLocalStorage('businessId');
    const {value: country, setValue: setCountry} = useLocalStorage('country');
    const {value: flow, setValue: setFlow} = useLocalStorage('flow');
    const endpoints = useRef({} as Record<RegionId, Record<ServiceResource, ServiceEndpoint>>);
    const settings = useRef({} as Settings);
    const currentBusinessId = useRef('');
    const currentCountry = useRef('');

    function getEndpoints() {
        return endpoints.current;
    }

    function getCurrentServiceEndpointFactory(country?: string) {
        return (service: ServiceResource) => {
            return country ? getEndpoints()[COUNTRY_TO_REGION_MAP[country]][service] : {};
        };
    }

    async function getAwsCredentials(
        businessContext: string[],
        regionId?: string
    ): Promise<Permissions.Types.GetAwsCredentialsResponse> {
        const apiResult = (await RestAPI.post('PermissionsApi', '/aws-credentials', {
            queryStringParameters: regionId
                ? {
                      regionId: regionId,
                  }
                : {},
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            },
            body: {
                businessContext: businessContext,
            },
        })) as Permissions.Types.GetAwsCredentialsResponse;

        return apiResult;
    }

    /**
     * We are doing this so we can keep the current Credentials instance and refreshCredentials works.
     */
    function updateEndpointsInplace(newEndpoints: Record<RegionId, Record<ServiceResource, ServiceEndpoint>>) {
        for (const [regionId, regionalEndpoints] of Object.entries(newEndpoints)) {
            // Create new region if not does exist
            if (!endpoints.current[regionId]) {
                endpoints.current[regionId] = {};
            }

            for (const [service, newEndpoints] of Object.entries(regionalEndpoints)) {
                if (endpoints.current[regionId][service]) {
                    endpoints.current[regionId][service].endpoint = newEndpoints.endpoint;
                    endpoints.current[regionId][service].region = newEndpoints.region;
                    endpoints.current[regionId][service].credentials.expireTime = newEndpoints.credentials.expireTime;
                    endpoints.current[regionId][service].credentials.accessKeyId = newEndpoints.credentials.accessKeyId;
                    endpoints.current[regionId][service].credentials.sessionToken = newEndpoints.credentials.sessionToken;
                    endpoints.current[regionId][service].credentials.secretAccessKey = newEndpoints.credentials.secretAccessKey;
                } else {
                    // if it does not exist before, just set the value
                    endpoints.current[regionId][service] = newEndpoints;
                }
            }
        }
    }

    // refresh all credentials
    async function refreshAllCredentials() {
        const responses = {} as Record<RegionId, Permissions.Types.GetAwsCredentialsResponse>;
        await Promise.all(
            Object.keys(RegionId).map(async (regionId: string) => {
                const businessContext =
                    currentBusinessId.current && currentCountry.current ? [currentBusinessId.current, currentCountry.current] : [];
                responses[regionId] = (await getAwsCredentials(businessContext, regionId))!;
            })
        );

        updateEndpointsInplace(extractServiceEndpoints(settings.current, responses, refreshAllCredentials));
    }

    useEffect(() => {
        const init = async () => {
            // Authenticate the user
            try {
                // Loading the app settings
                const response = await fetch(`${window.location.origin}/settings.json`);
                settings.current = await response.json();

                // Configure the app's Auth settings
                Analytics.addPluggable(new AWSKinesisFirehoseProvider());
                Amplify.configure(extractAmplifyConfiguration(settings.current));

                await Auth.currentAuthenticatedUser();

                const userEmail = await getUserEmail(refreshAllCredentials);

                // initialize following variables either by legacy way or by permission api
                let globalScopes: Record<string, string> = {};
                const businessContexts: BusinessContext[] = [];

                const regionAwareAwsCredentialsResponses = {} as Record<RegionId, Permissions.Types.GetAwsCredentialsResponse>;

                currentBusinessId.current = businessId || '';
                currentCountry.current = country || '';

                /**
                 * Make API calls for each region to get all the businesses that a user can access (read or write).
                 * TODO: The calls can be combined into one API call.
                 */
                await Promise.all(
                    Object.keys(RegionId).map(async (regionId: string) => {
                        const businessContext = businessId && country ? [businessId, country] : [];
                        regionAwareAwsCredentialsResponses[regionId] = (await getAwsCredentials(businessContext, regionId))!;
                    })
                );

                const awsCredentialsResponse = await getAwsCredentials([]);
                globalScopes = awsCredentialsResponse.globalScopes ?? {};

                businessContexts.push(
                    ...awsCredentialsResponse.accessList.map((v: Permissions.Types.PortalAccess) => ({
                        businessId: v.businessContext[0],
                        country: v.businessContext[1],
                        flow: v.businessContext[2]!,
                        regionId: COUNTRY_TO_REGION_MAP[v.businessContext[1]] as RegionId,
                        permissions: convertToUserPermission(v.scopes ?? {}, globalScopes),
                    }))
                );
                const initialBusinessSelection = getBusinessSelection(
                    country ? regionAwareAwsCredentialsResponses[COUNTRY_TO_REGION_MAP[country]] : awsCredentialsResponse!,
                    businessId || '',
                    country || '',
                    flow || ''
                );
                endpoints.current = extractServiceEndpoints(
                    settings.current,
                    regionAwareAwsCredentialsResponses,
                    refreshAllCredentials
                );

                const switchTo = async (businessId: string, country: string, flow: string) => {
                    const awsCredentialsResponse = await getAwsCredentials([businessId, country], COUNTRY_TO_REGION_MAP[country]);
                    const businessSelection = getBusinessSelection(awsCredentialsResponse, businessId, country, flow);

                    const regionId = COUNTRY_TO_REGION_MAP[country];
                    // update the endpoints
                    endpoints.current[regionId] = generateServiceCredentialsForRegion(
                        settings.current,
                        regionId,
                        awsCredentialsResponse.credentials!,
                        refreshAllCredentials
                    );

                    if (businessSelection) {
                        // Save for future
                        setBusinessId(businessSelection.businessId);
                        setCountry(businessSelection.country);
                        setFlow(businessSelection.flow);
                        currentBusinessId.current = businessSelection.businessId;
                        currentCountry.current = businessSelection.country;

                        const getCurrentServiceEndpoint = getCurrentServiceEndpointFactory(businessSelection.country);
                        businessSelection.configurationData = await getTenantConfigurations(
                            getCurrentServiceEndpoint(ServiceResource.ConfigurationView),
                            businessSelection
                        );

                        setAuth({
                            isAuthenticated: true,
                            authInformation: {
                                email: userEmail,
                                getEndpoints,
                                getCurrentServiceEndpoint: getCurrentServiceEndpoint,
                                businessContexts,
                                current: businessSelection,
                                globalScopes: globalScopes,
                                switchTo,
                            },
                        });
                    }
                };

                recordUserEvent({
                    eventData: {
                        email: userEmail,
                        loginTimeStamp: new Date().toLocaleString(),
                    },
                    eventName: PortalEventName.userAuthenticated,
                });

                if (initialBusinessSelection?.businessId && initialBusinessSelection?.country && initialBusinessSelection?.flow) {
                    const getCurrentServiceEndpoint = getCurrentServiceEndpointFactory(initialBusinessSelection?.country);
                    initialBusinessSelection.configurationData = await getTenantConfigurations(
                        getCurrentServiceEndpoint(ServiceResource.ConfigurationView),
                        initialBusinessSelection
                    );
                }

                setAuth({
                    isAuthenticated: true,
                    authInformation: {
                        email: userEmail,
                        getEndpoints,
                        getCurrentServiceEndpoint: getCurrentServiceEndpointFactory(initialBusinessSelection?.country),
                        businessContexts,
                        current: initialBusinessSelection,
                        globalScopes: globalScopes,
                        switchTo,
                    },
                });
            } catch (error) {
                console.error(error);
                // TODO: handle unauthentication error and other errors properly
                setAuth({
                    isAuthenticated: false,
                });
                await signIn();
                return;
            }
        };

        init();
    }, []);

    return auth;
}

async function signIn() {
    await Auth.signOut();
    await Auth.federatedSignIn({customProvider: 'Midway'});
}

function getBusinessSelection(
    awsCredentialsResponse: Permissions.Types.GetAwsCredentialsResponse,
    businessId: string,
    country: string,
    flow: string
): BusinessContext | undefined {
    const regionId = COUNTRY_TO_REGION_MAP[country];

    return awsCredentialsResponse.accessList
        .filter((v) => v.businessContext[0] === businessId)
        .filter((v) => v.businessContext[1] === country)
        .filter((v) => v.businessContext[2] === flow)
        .map((v) => ({
            businessId: v.businessContext[0],
            country: v.businessContext[1],
            flow: v.businessContext[2],
            regionId: regionId,
            permissions: convertToUserPermission(v.scopes!, awsCredentialsResponse.globalScopes ?? {}),
        }))[0];
}

function extractAmplifyConfiguration(settings: Settings) {
    return {
        Auth: {
            region: settings.region,
            identityPoolId: settings.cognitoIdentityPoolId,
            userPoolId: settings.cognitoUserPoolId,
            userPoolWebClientId: settings.cognitoAppClientId,
            mandatorySignIn: true,
            oauth: {
                domain: settings.cognitoAuthDomain,
                scope: ['openid', 'email', 'profile'],
                redirectSignIn: window.location.origin,
                redirectSignOut: window.location.origin,
                responseType: 'code',
            },
        },
        API: {
            endpoints: [
                {
                    name: 'PermissionsApi',
                    endpoint: settings.permissionsApiEndpoint,
                    region: settings.region,
                },
            ],
        },
        AWSKinesisFirehose: {
            region: settings.region,
        },
    };
}

async function getUserEmail(refreshCredentials: () => Promise<void>): Promise<string> {
    const currentSession = await Auth.currentSession();
    const payload = currentSession.getIdToken().payload;

    return payload.email;
}

function extractServiceEndpoints(
    settings: Settings,
    getAwsCredentialsResponses: Record<RegionId, Permissions.Types.GetAwsCredentialsResponse>,
    refreshCredentials: () => Promise<void>
): Record<RegionId, Record<ServiceResource, ServiceEndpoint>> {
    const endpoints = {} as Record<RegionId, Record<ServiceResource, ServiceEndpoint>>;
    Object.entries(getAwsCredentialsResponses).forEach((entry) => {
        endpoints[entry[0]] = generateServiceCredentialsForRegion(settings, entry[0], entry[1].credentials!, refreshCredentials);
    });
    return endpoints;
}

function generateServiceCredentialsForRegion(
    settings: Settings,
    regionId: string,
    credentials: Permissions.Types.Credentials[],
    refreshCredentials: () => Promise<void>
): Record<ServiceResource, ServiceEndpoint> {
    return {
        [ServiceResource.ConfigurationView]: {
            endpoint: settings[regionId].configurationStoreApiEndpoint,
            region: settings.region,
            credentials: credentials
                .filter((v) => v.serviceName === 'configurationView')
                .map((v) => generateRefreshableCredentials(v, refreshCredentials))[0]!,
        },
        [ServiceResource.OrchestratorView]: {
            endpoint: settings[regionId].orchestratorApiEndpoint,
            region: settings.region,
            credentials: credentials
                .filter((v) => v.serviceName === 'orchestratorView')
                .map((v) => generateRefreshableCredentials(v, refreshCredentials))[0]!,
        },
        [ServiceResource.OrchestratorEdit]: {
            endpoint: settings[regionId].orchestratorApiEndpoint,
            region: settings.region,
            credentials: credentials
                .filter((v) => v.serviceName === 'orchestratorEdit')
                .map((v) => generateRefreshableCredentials(v, refreshCredentials))[0]!,
        },
        [ServiceResource.ForecastStoreView]: {
            endpoint: settings[regionId].forecastStoreApiEndpoint,
            region: settings.region,
            credentials: credentials
                .filter((v) => v.serviceName === 'forecastStoreView')
                .map((v) => generateRefreshableCredentials(v, refreshCredentials))[0]!,
        },
        [ServiceResource.ForecastStoreEdit]: {
            endpoint: settings[regionId].forecastStoreApiEndpoint,
            region: settings.region,
            credentials: credentials
                .filter((v) => v.serviceName === 'forecastStoreEdit')
                .map((v) => generateRefreshableCredentials(v, refreshCredentials))[0]!,
        },
        [ServiceResource.ForecastStoreInsights]: {
            endpoint: settings[regionId].forecastStoreApiEndpoint,
            region: settings.region,
            credentials: credentials
                .filter((v) => v.serviceName === 'forecastStoreInsights')
                .map((v) => generateRefreshableCredentials(v, refreshCredentials))[0]!,
        },
        [ServiceResource.MerchantAutomation]: {
            endpoint: settings[regionId].merchantAutomationApiEndpoint,
            region: settings[regionId].merchantAutomationApiEndpoint.split('.')[2],
            credentials: credentials
                .filter((v) => v.serviceName === 'merchantAutomation')
                .map((v) => generateRefreshableCredentials(v, refreshCredentials))[0]!,
        },
        [ServiceResource.ExplainabilityView]: {
            endpoint: settings[regionId].analyticsServiceApiEndpoint,
            region: settings[regionId].analyticsServiceApiEndpoint.split('.')[2],
            credentials: credentials
                .filter((v) => v.serviceName === 'explainabilityView')
                .map((v) => generateRefreshableCredentials(v, refreshCredentials))[0]!,
        },
        [ServiceResource.StoreMetadata]: {
            endpoint: settings[regionId].storeMetadataApiEndpoint,
            region: settings[regionId].storeMetadataApiEndpoint.split('.')[2],
            credentials: credentials
                .filter((v) => v.serviceName === 'storeMetadata')
                .map((v) => generateRefreshableCredentials(v, refreshCredentials))[0]!,
        },
    };
}

function generateRefreshableCredentials(v: Permissions.Credentials, refreshCredentials: () => Promise<void>) {
    return new RefreshableCredentials({
        accessKeyId: v.awsCredentials!.accessKeyId,
        secretAccessKey: v.awsCredentials!.secretAccessKey,
        sessionToken: v.awsCredentials!.sessionToken,
        expireTime: new Date(Number(v.awsCredentials!.expiration)),
        refreshCredentials,
    });
}

function convertToUserPermission(scopes: Record<string, string>, globalScopes: Record<string, string>): UserPermission {
    return {
        canManageForecastWorkflows: scopes['workflows']?.split(',').includes('edit') ?? false,
        canUploadMarketingInputs: scopes['marketingUplifts']?.split(',').includes('edit') ?? false,
        canUploadBulkOverrides: scopes['forecasts']?.split(',').includes('edit') ?? false,
        canViewForecasts: scopes['forecasts']?.split(',').includes('view') ?? false,
        canEditForecasts: scopes['forecasts']?.split(',').includes('edit') ?? false,
        canViewAccuracyDashboard: scopes['accuracyDashboard']?.split(',').includes('view') ?? false,
        canViewExplainability: scopes['explainabilityData']?.split(',').includes('view') ?? false,
        canViewConfigurations: scopes['configurations']?.split(',').includes('view') ?? false,
        canViewStoreMetadata: scopes['metadata']?.split(',').includes('view') ?? false,
    };
}

async function getTenantConfigurations(
    clientConfigs: AbeautifulandamazingF3ExcelsiorConfigurationLambdaModel.Types.ClientConfiguration,
    businessSelection: BusinessContext
) {
    if (!businessSelection.permissions.canViewConfigurations) {
        return JSON.parse('{}') as ConfigurationData;
    }

    try {
        const client = new AbeautifulandamazingF3ExcelsiorConfigurationLambdaModel(clientConfigs);
        const response = await client
            .getConfiguration({
                business: businessSelection.businessId,
                country: businessSelection.country,
                flow: businessSelection.flow,
                groupId: 'forecast',
            })
            .promise();
        return JSON.parse(response.configuration ?? '{}') as ConfigurationData;
    } catch (error: any) {
        // TODO: find a way to log these kinds of errors to the cloud
        return JSON.parse('{}') as ConfigurationData;
    }
}

interface RefreshableCredentialsOptions {
    expireTime: Date;
    refreshCredentials: () => Promise<void>;
}

class RefreshableCredentials extends Credentials {
    private readonly refreshCredentials: () => Promise<void>;

    constructor(options: CredentialsOptions & RefreshableCredentialsOptions) {
        super(options);
        this.expireTime = options.expireTime;
        this.refreshCredentials = options.refreshCredentials;
    }

    refresh(callback: (err?: AWSError) => void) {
        this.refreshCredentials().then(
            () => callback(),
            (reason) => callback(reason as AWSError)
        );
    }
}
