import React, {useState} from 'react';
import {Input, Toggle, Select, DatePicker, FormField, SpaceBetween, TimeInput} from '@amzn/awsui-components-react';
import {EditorType, EditorConfig} from './metadata-record-edit-config';

interface EditorProps {
    value: any;
    onChange: (value: any) => void;
    [key: string]: any;
}

export const TextEditor: React.FC<EditorProps> = ({value, onChange, ...props}) => (
    <Input value={value} onChange={({detail}) => onChange(detail.value)} {...props} />
);

export const SelectEditor: React.FC<EditorProps> = ({value, onChange, options = [], ...props}) => (
    <Select
        selectedOption={options.find((option) => option.value === value) || null}
        onChange={({detail}) => onChange(detail.selectedOption.value)}
        options={options}
        {...props}
    />
);

export const TimestampEditor: React.FC<EditorProps> = ({value, onChange, ...props}) => {
    const initialDate = value ? new Date(value) : new Date();
    const [date, setDate] = useState(initialDate.toISOString().split('T')[0]);
    const [time, setTime] = useState(
        initialDate.toLocaleTimeString('en-US', {
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
            timeZone: 'UTC',
        })
    );

    const formatDateTime = (dateStr: string, timeStr: string) => {
        try {
            const [hours, minutes] = timeStr.split(':').map(Number);
            const newDate = new Date(dateStr);
            newDate.setUTCHours(hours, minutes, 0, 0);
            return isNaN(newDate.getTime()) ? null : newDate;
        } catch (e) {
            return null;
        }
    };

    const handleDateChange = ({detail}: {detail: {value: string}}) => {
        setDate(detail.value);
        const newDateTime = formatDateTime(detail.value, time);
        if (newDateTime) {
            onChange(newDateTime);
        }
    };

    const handleTimeChange = ({detail}: {detail: {value: string}}) => {
        setTime(detail.value);
        const newDateTime = formatDateTime(date, detail.value);
        if (newDateTime) {
            onChange(newDateTime);
        }
    };

    return (
        <SpaceBetween direction="horizontal" size="xs">
            <FormField>
                <DatePicker value={date} onChange={handleDateChange} expandToViewport {...props} />
            </FormField>
            <FormField>
                <TimeInput value={time} onChange={handleTimeChange} format="hh:mm" placeholder="hh:mm" />
            </FormField>
        </SpaceBetween>
    );
};

export const EDITOR_COMPONENTS: Record<EditorType, EditorConfig> = {
    text: {component: TextEditor},
    number: {component: TextEditor, props: {type: 'number'}},
    select: {component: SelectEditor},
    date: {component: DatePicker},
    timestamp: {
        component: TimestampEditor,
        props: {
            dateOnly: true,
            expandToViewport: true,
        },
    },
};
