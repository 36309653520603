import React from 'react';
import {Box} from '@amzn/awsui-components-react';
import {FieldConfig, formatFieldValue} from './editors/metadata-record-edit-config';
import {EDITOR_COMPONENTS} from './editors';

export interface EditableMetadataFieldProps {
    label: string;
    value: any;
    isEditing: boolean;
    onValueChange: (value: any) => void;
    fieldConfig: FieldConfig;
}

export const EditableMetadataField = React.memo(
    ({label, value, isEditing, onValueChange, fieldConfig}: EditableMetadataFieldProps) => {
        if (!isEditing || !fieldConfig.editable) {
            return (
                <div>
                    <Box variant="awsui-key-label" color="text-label">
                        {label}
                    </Box>
                    <Box>{formatFieldValue(value)}</Box>
                </div>
            );
        }

        const EditorComponent = EDITOR_COMPONENTS[fieldConfig.type].component;
        const editorProps = EDITOR_COMPONENTS[fieldConfig.type].props || {};

        return (
            <div>
                <Box variant="awsui-key-label" color="text-label">
                    {label}
                </Box>
                <EditorComponent
                    value={value}
                    onChange={onValueChange}
                    {...editorProps}
                    {...(fieldConfig.options && {options: fieldConfig.options})}
                />
            </div>
        );
    }
);

EditableMetadataField.displayName = 'EditableMetadataField';
