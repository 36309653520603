import React, {useState, useMemo} from 'react';
import {Box, Button, Popover, StatusIndicator} from '@amzn/awsui-components-react';
import {DashboardEvent} from '@amzn/f3-excelsior-orchestrator-api/clients/orchestrator';
import {useAuth} from '../hooks/use-auth';
import {useListDashboardEvents} from '../hooks/use-orchestrator-api';
import moment from 'moment';
import {generatePopoverContent, getDashEventType} from '../operational-dashboard/event-popover';
import '../../assets/styles/events-timeline-styles.scss';

export interface TimelineEvent {
    title: string;
    date: string;
    status: 'success' | 'in-progress' | 'pending' | 'error';
    timestamp?: number;
    originalEvent?: DashboardEvent;
}

interface TimelineProps {
    onError?: (error: Error) => void;
}

const WEEK = 7 * 24 * 60 * 60 * 1000;

const getEventStatus = (status: string): TimelineEvent['status'] => {
    switch (status.toLowerCase()) {
        case 'succeed':
            return 'success';
        case 'not_started':
            return 'pending';
        case 'running':
            return 'in-progress';
        case 'error':
        case 'failed':
            return 'error';
        default:
            return 'pending';
    }
};

const getEventTitle = (event: DashboardEvent): string => {
    const eventTypes = [
        {key: 'generator', title: 'Forecast Generation'},
        {key: 'promote', title: 'Promote'},
        {key: 'publish', title: 'Publish'},
        {key: 'promoteAndPublish', title: 'Promote & Publish'},
        {key: 'calculateAccuracy', title: 'Accuracy Calculation'},
        {key: 'percentileForecast', title: 'Percentile Forecast'},
    ];

    const baseTitle =
        eventTypes.find((type) => event[type.key as keyof DashboardEvent])?.title ??
        (event.ingestion ? `Ingest ${event.ingestion.data}` : 'Unknown Event');

    return `${baseTitle}${event.adhoc ? ' (Adhoc)' : ''}`;
};

export const EventsTimeline: React.FC<TimelineProps> = React.memo(({onError}) => {
    const auth = useAuth();
    const [isRefreshing, setIsRefreshing] = useState(false);

    const endpoints = useMemo(() => {
        return auth.authInformation?.getEndpoints();
    }, [auth.authInformation]);

    const businessContext = useMemo(() => {
        return auth.authInformation?.current ? [auth.authInformation.current] : null;
    }, [auth.authInformation]);

    const {maxStart, maxEnd} = useMemo(() => {
        const currentDateTime = new Date();
        return {
            maxStart: new Date(currentDateTime.getTime() - 1 * WEEK),
            maxEnd: new Date(currentDateTime.getTime() + 1 * WEEK),
        };
    }, []);

    const {dashboardEventFetchStatus, refreshSingle} = useListDashboardEvents(endpoints!, businessContext!, maxStart, maxEnd, true);

    const timelineEvents = useMemo(() => {
        try {
            const events = dashboardEventFetchStatus[0]?.events;
            if (!events?.length) return [];

            const currentDate = new Date();
            const currentTime = currentDate.getTime();

            const mappedEvents: TimelineEvent[] = events
                .map((event) => ({
                    title: getEventTitle(event),
                    date: moment(event.scheduledTime).format('MMM DD, HH:mm'),
                    status: getEventStatus(event.status),
                    timestamp: new Date(event.scheduledTime).getTime(),
                    originalEvent: event,
                }))
                .sort((a, b) => a.timestamp! - b.timestamp!);

            const pastEvents = mappedEvents.filter((event) => event.timestamp! <= currentTime);
            const futureEvents = mappedEvents.filter((event) => event.timestamp! > currentTime);
            const currentEvent = pastEvents[pastEvents.length - 1] || null;

            return [...pastEvents.slice(-3, -1), ...(currentEvent ? [currentEvent] : []), ...futureEvents.slice(0, 2)];
        } catch (err) {
            onError?.(err as Error);
            return [];
        }
    }, [dashboardEventFetchStatus, onError]);

    const handleRefresh = async () => {
        if (!businessContext?.[0] || isRefreshing) return;

        setIsRefreshing(true);
        try {
            await refreshSingle(businessContext[0]);
        } catch (err) {
            onError?.(err as Error);
        } finally {
            setIsRefreshing(false);
        }
    };

    if (!endpoints || !businessContext || !timelineEvents.length) return null;

    return (
        <Box padding="xxxs">
            <div className="timeline-container">
                <div className="timeline-items-wrapper">
                    {timelineEvents.map((event, index) => (
                        <React.Fragment key={index}>
                            <div className="timeline-item">
                                <Popover
                                    dismissAriaLabel="Close"
                                    position="top"
                                    size="large"
                                    triggerType="text-inline"
                                    fixedWidth
                                    header={getDashEventType(event.originalEvent!)}
                                    content={generatePopoverContent(event.originalEvent!)}
                                >
                                    <StatusIndicator
                                        type={event.status}
                                        colorOverride={
                                            event.status === 'in-progress'
                                                ? 'green'
                                                : event.status === 'success'
                                                ? 'blue'
                                                : undefined
                                        }
                                        data-testid="status-indicator"
                                        data-status={event.status}
                                    >
                                        <div className="timeline-content">
                                            <span className="timeline-title">{event.title}</span>
                                            <span className="timeline-date">{event.date}</span>
                                        </div>
                                    </StatusIndicator>
                                </Popover>
                            </div>
                            {index < timelineEvents.length - 1 && <div className="timeline-connector" />}
                        </React.Fragment>
                    ))}
                    <div className="timeline-refresh">
                        <Button
                            variant="icon"
                            iconName="refresh"
                            loading={isRefreshing}
                            disabled={isRefreshing}
                            onClick={handleRefresh}
                            ariaLabel="Refresh timeline"
                        />
                    </div>
                </div>
            </div>
        </Box>
    );
});

EventsTimeline.displayName = 'EventsTimeline';
