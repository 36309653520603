import {MetadataRecord} from '@amzn/f3-excelsior-store-metadata-api/clients/apiforexcelsiorstoremetadata';
import {ComponentType} from 'react';

export type EditorType = 'text' | 'number' | 'select' | 'date' | 'timestamp';

export interface EditorConfig {
    component: ComponentType<any>;
    props?: Record<string, any>;
}

export interface FieldConfig {
    type: EditorType;
    label: string;
    editable?: boolean;
    validation?: (value: any) => string | undefined;
    options?: Array<{label: string; value: any}>;
    description?: string;
}

export const formatFieldValue = (value: any): string => {
    if (value === undefined || value === null) return '—';
    if (value instanceof Date) {
        return value.toISOString().replace('T', ' ').replace('Z', '');
    }
    return String(value);
};

export const formatFieldName = (field: string): string =>
    field
        .split(/(?=[A-Z])/)
        .join(' ')
        .replace(/^\w/, (c) => c.toUpperCase());

const YES_NO_OPTIONS = [
    {label: '-', value: null},
    {label: 'Y', value: 'Y'},
    {label: 'N', value: 'N'},
];

export const FIELD_CONFIGS: Partial<Record<keyof MetadataRecord, FieldConfig>> = {
    merchantCustomerId: {
        type: 'number',
        label: 'Merchant Customer ID',
        editable: false,
    },
    fulfillmentManagerId: {
        type: 'number',
        label: 'Fulfillment Manager ID',
        editable: false,
    },
    marketplaceId: {
        type: 'number',
        label: 'Marketplace ID',
        editable: false,
    },
    programRegionId: {
        type: 'text',
        label: 'Program Region ID',
        editable: false,
    },
    warehouseId: {
        type: 'text',
        label: 'Warehouse ID',
        editable: false,
    },
    businessProgramType: {
        type: 'text',
        label: 'Business Program Type',
        editable: false,
    },
    isRetail: {
        type: 'select',
        label: 'Is Retail',
        editable: true,
        options: YES_NO_OPTIONS,
    },
    is3p: {
        type: 'select',
        label: 'Is 3P',
        editable: true,
        options: YES_NO_OPTIONS,
    },
    isHub: {
        type: 'select',
        label: 'Is Hub',
        editable: true,
        options: YES_NO_OPTIONS,
    },
    marketplaceName: {
        type: 'text',
        label: 'Marketplace Name',
        editable: true,
    },
    marketplaceTimeZone: {
        type: 'text',
        label: 'Marketplace Timezone',
        editable: false,
    },
    currencyCode: {
        type: 'text',
        label: 'Currency Code',
        editable: false,
    },
    localTimeZone: {
        type: 'text',
        label: 'Local Timezone',
        editable: false,
    },
    fgid: {
        type: 'text',
        label: 'FGID',
        editable: false,
    },
    retailIog: {
        type: 'text',
        label: 'Retail IOG',
        editable: false,
    },
    offerListingDiscriminator: {
        type: 'text',
        label: 'Offer Listing Discriminator',
        editable: false,
    },
    isActive: {
        type: 'select',
        label: 'Is Active',
        editable: true,
        options: YES_NO_OPTIONS,
    },
    launchDate: {
        type: 'timestamp',
        label: 'Launch Date',
        editable: true,
    },
    closureDate: {
        type: 'timestamp',
        label: 'Closure Date',
        editable: true,
    },
    isPickup: {
        type: 'select',
        label: 'Is Pickup',
        editable: true,
        options: YES_NO_OPTIONS,
    },
    hrmConfigCity: {
        type: 'text',
        label: 'HRM Config City',
        editable: false,
    },
    location: {
        type: 'text',
        label: 'Location',
        editable: true,
    },
    scarUniqueMerchantName: {
        type: 'text',
        label: 'SCAR Unique Merchant Name',
        editable: true,
    },
    isWfm: {
        type: 'select',
        label: 'Is WFM',
        editable: true,
        options: YES_NO_OPTIONS,
    },
    originSystemSource: {
        type: 'text',
        label: 'Origin System Source',
        editable: false,
    },
    city: {
        type: 'text',
        label: 'City',
        editable: true,
    },
    almBrandId: {
        type: 'text',
        label: 'ALM Brand ID',
        editable: false,
    },
    almBrandName: {
        type: 'text',
        label: 'ALM Brand Name',
        editable: false,
    },
    almGroupId: {
        type: 'text',
        label: 'ALM Group ID',
        editable: false,
    },
    almGroupName: {
        type: 'text',
        label: 'ALM Group Name',
        editable: false,
    },
    fulfillmentType: {
        type: 'text',
        label: 'Fulfillment Type',
        editable: true,
    },
    fulfillmentChannelName: {
        type: 'text',
        label: 'Fulfillment Channel Name',
        editable: true,
    },
    almPhysicalStoreId: {
        type: 'text',
        label: 'ALM Physical Store ID',
        editable: false,
    },
    listerId: {
        type: 'text',
        label: 'Lister ID',
        editable: true,
    },
    storeName: {
        type: 'text',
        label: 'Store Name',
        editable: true,
    },
    marketingStoreName: {
        type: 'text',
        label: 'Marketing Store Name',
        editable: true,
    },
    storeFormat: {
        type: 'text',
        label: 'Store Format',
        editable: false,
    },
    state: {
        type: 'text',
        label: 'State',
        editable: false,
    },
    geography: {
        type: 'text',
        label: 'Geography',
        editable: false,
    },
    geographyCode: {
        type: 'text',
        label: 'Geography Code',
        editable: false,
    },
    scarBusinessType: {
        type: 'text',
        label: 'SCAR Business Type',
        editable: false,
    },
    storeCode: {
        type: 'text',
        label: 'Store Code',
        editable: false,
    },
    storeAcronym: {
        type: 'text',
        label: 'Store Acronym',
        editable: false,
    },
    almStaticCapabilities: {
        type: 'text',
        label: 'ALM Static Capabilities',
        editable: false,
    },
    isCurrent: {
        type: 'select',
        label: 'Is Current',
        editable: true,
        options: [
            {label: '1', value: 1},
            {label: '0', value: 0},
        ],
    },
    encryptedMerchantId: {
        type: 'text',
        label: 'Encrypted Merchant ID',
        editable: false,
    },
    stationCode: {
        type: 'text',
        label: 'Station Code',
        editable: true,
    },
    complianceStatus: {
        type: 'text',
        label: 'Compliance Status',
        editable: true,
    },
    additionalMerchantIds: {
        type: 'text',
        label: 'Additional Merchant IDs',
        editable: true,
    },
    regionId: {
        type: 'text',
        label: 'Region ID',
        editable: false,
    },
    countryCode: {
        type: 'text',
        label: 'Country Code',
        editable: false,
    },
};
