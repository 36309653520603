import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Notification} from '../navigation/page-layout';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import {translateErrorToReactNode} from '../common';
import {F3ExcelsiorForecastStoreLambda} from '@amzn/f3-excelsior-forecast-store-lambda';
import {useGetAggregateCalculationStatus, useGetDashboardUrl, useGetForecastDescription} from '../hooks/use-forecast-store-api';
import {EventsTimeline} from './events-timeline';
import PortalLoader from '../common/PortalLoader';
import moment from 'moment';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import '../../assets/styles/forecast-styles.scss';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:SS';
const CREATION_DATE_FORMAT = 'MMMM Do YYYY, h:mm:ss a';
const PREVIOUS_WEEK_INTERVAL = 1;
const PREVIOUS_DAY_INTERVAL = 1;
const FORECAST_WEEK_INTERVAL = 4;
const FORECAST_DAY_INTERVAL = 7;
const WINDOW_DEFAULT_ENDTIME_HOUR = 23;
const WINDOW_DEFAULT_ENDTIME_MINUTE = 59;

export function ViewForecast(props: {pushNotification: (notification: Notification) => void}) {
    const auth = useAuth();
    const initialized = useRef(false);

    const [eventsExpanded, setEventsExpanded] = useState(false);
    const clientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.ForecastStoreView);

    function createErrorListener<T>(header: string) {
        return (e: any) => {
            props.pushNotification({
                type: 'error',
                content: translateErrorToReactNode(e),
                header,
            });
        };
    }

    const {status: getForecastDescriptionStatus, value: getForecastDescriptionResponse} = useGetForecastDescription(
        clientConfiguration,
        {
            businessId: auth.authInformation!.current!.businessId,
            country: auth.authInformation!.current!.country,
            flow: auth.authInformation!.current!.flow,
        },
        true,
        createErrorListener('GetForecastDescription failed'),
        [auth]
    );

    const {status: getAggregateCalculationStatusExecuteStatus, value: getAggregateCalculationStatusResponse} =
        useGetAggregateCalculationStatus(
            clientConfiguration,
            {
                businessId: auth.authInformation!.current!.businessId,
                country: auth.authInformation!.current!.country,
                flow: auth.authInformation!.current!.flow,
            },
            true,
            createErrorListener('GetAggregateCalculationStatus failed'),
            [auth]
        );

    const {status: getDashboardUrlStatus, value: getDashboardUrlResponse} = useGetDashboardUrl(
        clientConfiguration,
        {
            businessId: auth.authInformation!.current!.businessId,
            country: auth.authInformation!.current!.country,
            flow: auth.authInformation!.current!.flow,
        },
        true,
        createErrorListener('GetDashboardUrl failed'),
        [auth]
    );

    const isLoading =
        getForecastDescriptionStatus === 'idle' ||
        getForecastDescriptionStatus === 'pending' ||
        getDashboardUrlStatus === 'idle' ||
        getDashboardUrlStatus === 'pending' ||
        getAggregateCalculationStatusExecuteStatus === 'idle' ||
        getAggregateCalculationStatusExecuteStatus === 'pending';

    if (isLoading) {
        return <PortalLoader />;
    }

    const forecastIdMetadata = getForecastDescriptionResponse?.latestForecastIdsMetadata[0];
    const dailyStartDate = moment(forecastIdMetadata?.forecastStartDate)
        .subtract(PREVIOUS_WEEK_INTERVAL, 'weeks')
        .format(DATE_FORMAT);
    const hourlyStartDate = moment(forecastIdMetadata?.forecastStartDate)
        .subtract(PREVIOUS_DAY_INTERVAL, 'days')
        .format(DATE_FORMAT);
    const dailyEndDate = moment(forecastIdMetadata?.forecastStartDate).add(FORECAST_WEEK_INTERVAL, 'weeks').format(DATE_FORMAT);
    const hourlyEndDate = moment(forecastIdMetadata?.forecastStartDate)
        .add(FORECAST_DAY_INTERVAL, 'days')
        .add(WINDOW_DEFAULT_ENDTIME_HOUR, 'hours')
        .add(WINDOW_DEFAULT_ENDTIME_MINUTE, 'minutes')
        .format(DATE_FORMAT);

    if (!initialized.current) {
        if (getForecastDescriptionResponse?.latestForecastIdsMetadata.length !== 1) {
            const bannerMessage = `There is no forecast for business: ${auth.authInformation!.current!.businessId}, country: ${
                auth.authInformation!.current!.country
            }, flow: ${auth.authInformation!.current!.flow}`;

            props.pushNotification({
                content: bannerMessage,
                type: 'error',
            });
        } else {
            const ratioMessage = getRatiosBannerMessage(getAggregateCalculationStatusResponse);
            const createdDateTime = new Date(forecastIdMetadata?.createdTime + 'Z');
            const createdTime = moment(createdDateTime).format(CREATION_DATE_FORMAT);

            const bannerMessage =
                ratioMessage !== '' ? (
                    <React.Fragment>
                        {'Creation Time: ' + createdTime}
                        <br></br>
                        {ratioMessage}
                    </React.Fragment>
                ) : (
                    <React.Fragment>{'Creation Time: ' + createdTime}</React.Fragment>
                );

            props.pushNotification({
                content: bannerMessage,
                type: 'info',
            });
        }

        initialized.current = true;
    }

    if (getForecastDescriptionResponse?.latestForecastIdsMetadata.length !== 1) {
        return <React.Fragment></React.Fragment>;
    }

    return (
        <React.Fragment>
            <div className="timeline-hover-area" onClick={() => setEventsExpanded(!eventsExpanded)}>
                <span className="timeline-toggle-text">
                    {!eventsExpanded ? 'Show Events' : 'Hide Events'}
                    <span className="timeline-collapse-indicator">{!eventsExpanded ? ' ▼' : ' ▲'}</span>
                </span>
            </div>
            {eventsExpanded && (
                <EventsTimeline
                    onError={(error) => {
                        props.pushNotification({
                            type: 'error',
                            content: translateErrorToReactNode(error),
                            header: 'Timeline Error',
                        });
                    }}
                />
            )}
            <ForecastQuicksightDashboard
                url={getDashboardUrlResponse?.dashboardUrl || ''}
                dailyStartDate={dailyStartDate}
                dailyEndDate={dailyEndDate}
                hourlyStartDate={hourlyStartDate}
                hourlyEndDate={hourlyEndDate}
                forecastId={forecastIdMetadata?.forecastId || ''}
                pushNotification={props.pushNotification}
                calculationGroupId={getAggregateCalculationStatusResponse?.statuses[0]?.calculationGroupId ?? ''}
            />
        </React.Fragment>
    );
}

interface ForecastQuicksightDashboardProps {
    url: string;
    dailyStartDate: string;
    hourlyStartDate: string;
    dailyEndDate: string;
    hourlyEndDate: string;
    forecastId: string;
    calculationGroupId: string;
    pushNotification: (notification: Notification) => void;
}

function ForecastQuicksightDashboard(props: ForecastQuicksightDashboardProps) {
    const ref = useRef<HTMLDivElement | null>(null);

    function onError(error: any) {
        props.pushNotification({
            type: 'error',
            header: 'Embeddeding Quicksight Dashboard failed',
            content: translateErrorToReactNode(error),
        });
    }

    useEffect(() => {
        const dashboard = QuickSightEmbedding.embedDashboard({
            url: props.url,
            // ref.current is definitely not null in useEffect
            container: ref.current || '',
            locale: 'en-US',
            footerPaddingEnabled: true,
            parameters: {
                forecastId: props.forecastId,
                dailyStartDateFilter: props.dailyStartDate,
                hourlyStartDateFilter: props.hourlyStartDate,
                dailyEndDateFilter: props.dailyEndDate,
                hourlyEndDateFilter: props.hourlyEndDate,
                calculationGroupId: props.calculationGroupId,
            },
        });
        dashboard.on('error', onError);
    }, [props.forecastId]);

    return <div id={'embeddingContainer'} ref={ref} />;
}

function getRatiosBannerMessage(
    getAggregateCalculationStatusResponse?: F3ExcelsiorForecastStoreLambda.Types.GetAggregateCalculationStatusResponse
) {
    let bannerMessage = '';
    if (getAggregateCalculationStatusResponse) {
        for (const data of getAggregateCalculationStatusResponse?.statuses) {
            const ratiosStatus = data?.status ?? '';
            bannerMessage +=
                ratiosStatus.toLowerCase() === 'success'
                    ? `${data.calculationName} calculation succeeded time: ` +
                      moment(new Date(data?.lastModifiedTime as string)).format(CREATION_DATE_FORMAT) +
                      '\n'
                    : ratiosStatus.toLowerCase() !== 'success' && ratiosStatus !== ''
                    ? `${data.calculationName} calculation in Progress.\n`
                    : '';
        }
    }
    return bannerMessage;
}
