import {APIforExcelsiorStoreMetadata} from '@amzn/f3-excelsior-store-metadata-api';
import {AsyncStatus, useExecute} from './use-async';
import {DependencyList, useCallback, useState} from 'react';
import {wrapOnErrorListener} from '../common';

/* eslint-disable react-hooks/exhaustive-deps */
export function useGetStoreMetadata(
    clientConfiguration: APIforExcelsiorStoreMetadata.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new APIforExcelsiorStoreMetadata(clientConfiguration)
) {
    return useExecute<
        APIforExcelsiorStoreMetadata.Types.GetMetadataRecordOutput,
        (
            r: APIforExcelsiorStoreMetadata.Types.GetMetadataRecordInput
        ) => Promise<APIforExcelsiorStoreMetadata.Types.GetMetadataRecordOutput>
    >(
        useCallback(
            (request: APIforExcelsiorStoreMetadata.Types.GetMetadataRecordInput) =>
                wrapOnErrorListener(client.getMetadataRecord(request).promise(), onErrorListener),
            deps
        )
    );
}

export function useListStoreRecords(
    clientConfiguration: APIforExcelsiorStoreMetadata.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new APIforExcelsiorStoreMetadata(clientConfiguration)
) {
    return useExecute<
        APIforExcelsiorStoreMetadata.Types.ListStoreRecordsByNodeOutput,
        (
            r: APIforExcelsiorStoreMetadata.Types.ListStoreRecordsByNodeInput
        ) => Promise<APIforExcelsiorStoreMetadata.Types.ListStoreRecordsByNodeOutput>
    >(
        useCallback(
            (request: APIforExcelsiorStoreMetadata.Types.ListStoreRecordsByNodeInput) =>
                wrapOnErrorListener(client.listStoreRecordsByNode(request).promise(), onErrorListener),
            deps
        )
    );
}

export function useListMetadataAudits(
    clientConfiguration: APIforExcelsiorStoreMetadata.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new APIforExcelsiorStoreMetadata(clientConfiguration)
) {
    return useExecute<
        APIforExcelsiorStoreMetadata.Types.ListMetadataAuditsOutput,
        (
            r: APIforExcelsiorStoreMetadata.Types.ListMetadataAuditsInput
        ) => Promise<APIforExcelsiorStoreMetadata.Types.ListMetadataAuditsOutput>
    >(
        useCallback(
            (request: APIforExcelsiorStoreMetadata.Types.GetMetadataRecordInput) =>
                wrapOnErrorListener(client.listMetadataAudits(request).promise(), onErrorListener),
            deps
        )
    );
}

export function useSubmitMetadataOverride(
    clientConfiguration: APIforExcelsiorStoreMetadata.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new APIforExcelsiorStoreMetadata(clientConfiguration)
) {
    return useExecute<unknown, (r: APIforExcelsiorStoreMetadata.Types.SubmitMetadataOverrideInput) => Promise<unknown>>(
        useCallback(
            (request: APIforExcelsiorStoreMetadata.Types.SubmitMetadataOverrideInput) =>
                wrapOnErrorListener(client.submitMetadataOverride(request).promise(), onErrorListener),
            deps
        )
    );
}

export function useRemoveMetadataOverride(
    clientConfiguration: APIforExcelsiorStoreMetadata.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new APIforExcelsiorStoreMetadata(clientConfiguration)
) {
    return useExecute<unknown, (r: APIforExcelsiorStoreMetadata.Types.RemoveMetadataOverrideInput) => Promise<unknown>>(
        useCallback(
            (request: APIforExcelsiorStoreMetadata.Types.RemoveMetadataOverrideInput) =>
                wrapOnErrorListener(client.removeMetadataOverride(request).promise(), onErrorListener),
            deps
        )
    );
}

export function useListMetadataOverrides(
    clientConfiguration: APIforExcelsiorStoreMetadata.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new APIforExcelsiorStoreMetadata(clientConfiguration)
) {
    return useExecute<
        APIforExcelsiorStoreMetadata.Types.ListMetadataOverridesOutput,
        () => Promise<APIforExcelsiorStoreMetadata.Types.ListMetadataOverridesOutput>
    >(useCallback(() => wrapOnErrorListener(client.listMetadataOverrides().promise(), onErrorListener), deps));
}
