import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    Box,
    Button,
    ButtonDropdown,
    ColumnLayout,
    Container,
    Header,
    Input,
    Link,
    Modal,
    SpaceBetween,
    Table,
    Tabs,
} from '@amzn/awsui-components-react';
import {useListMetadataOverrides, useListStoreRecords, useRemoveMetadataOverride} from '../hooks/use-store-metadata-api';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import {translateErrorToReactNode} from '../common';
import {Notification} from '../navigation/page-layout';
import {
    MetadataOverrideRecord,
    MetadataOverrideRecords,
    StoreListRecord,
} from '@amzn/f3-excelsior-store-metadata-api/clients/apiforexcelsiorstoremetadata';
import {formatFieldValue} from './editors/metadata-record-edit-config';

export default function ListStoresPage({pushNotification}: {pushNotification: (notification: Notification) => void}) {
    const auth = useAuth();
    const clientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.StoreMetadata);
    const [activeTabId, setActiveTabId] = useState('stores');

    const [state, setState] = useState({
        nodeId: '',
        stores: [] as StoreListRecord[],
        loading: false,
    });

    const [overridesState, setOverridesState] = useState({
        selectedOverride: null as MetadataOverrideRecord | null,
        overrideToDelete: null as MetadataOverrideRecord | null,
        isRecordModalVisible: false,
        isDeleteModalVisible: false,
        overrides: [] as MetadataOverrideRecords,
    });

    const createErrorListener = useCallback(
        (header: string) => (error: Error) => {
            pushNotification({
                type: 'error',
                content: translateErrorToReactNode(error),
                header,
            });
        },
        [pushNotification]
    );

    const {execute: executeListStoreRecords, value: listStoreRecordsResponse} = useListStoreRecords(
        clientConfiguration,
        createErrorListener('ListStoreRecords failed'),
        [auth]
    );

    const {execute: executeListMetadataOverrides, value: listMetadataOverrideResponse} = useListMetadataOverrides(
        clientConfiguration,
        createErrorListener('ListMetadataOverrides failed'),
        [auth]
    );

    const {execute: executeUseRemoveMetadataOverride} = useRemoveMetadataOverride(
        clientConfiguration,
        createErrorListener('RemoveMetadataOverride failed'),
        [auth]
    );

    const handleSearch = useCallback(() => {
        if (!state.nodeId.trim()) return;
        setState((prev) => ({...prev, loading: true}));
        executeListStoreRecords({node: state.nodeId});
    }, [state.nodeId, executeListStoreRecords]);

    useEffect(() => {
        if (!listStoreRecordsResponse?.records) {
            setState((prev) => ({...prev, loading: false}));
            return;
        }
        setState((prev) => ({
            ...prev,
            stores: listStoreRecordsResponse.records,
            loading: false,
        }));
    }, [listStoreRecordsResponse]);

    const fetchOverrides = useCallback(() => {
        executeListMetadataOverrides();
    }, [executeListMetadataOverrides]);

    useEffect(() => {
        if (listMetadataOverrideResponse?.records) {
            setOverridesState((prevState) => ({
                ...prevState,
                overrides: listMetadataOverrideResponse.records,
            }));
        }
    }, [listMetadataOverrideResponse]);

    const handleRemoveOverride = useCallback(() => {
        if (overridesState.overrideToDelete) {
            setOverridesState((prevState) => ({
                ...prevState,
                isDeleteModalVisible: false,
            }));
            executeUseRemoveMetadataOverride({programRegionId: overridesState.overrideToDelete.programRegionId!})
                .then(() => {
                    pushNotification({
                        type: 'success',
                        content: 'Override removal submitted successfully.',
                    });
                    fetchOverrides();
                })
                .catch((error) => {
                    pushNotification({
                        type: 'error',
                        content: 'Failed to remove override.',
                    });
                });
        }
    }, [overridesState.overrideToDelete, pushNotification, executeUseRemoveMetadataOverride, fetchOverrides]);

    const storesColumnDefinitions = useMemo(
        () => [
            {
                id: 'warehouseId',
                header: 'Warehouse ID',
                cell: (item: StoreListRecord) => item.warehouseId,
                sortingField: 'warehouseId',
            },
            {
                id: 'almPhysicalStoreId',
                header: 'ALM Physical Store ID',
                cell: (item: StoreListRecord) => item.almPhysicalStoreId,
                sortingField: 'almPhysicalStoreId',
            },
            {
                id: 'merchantCustomerId',
                header: 'Merchant Customer ID',
                cell: (item: StoreListRecord) => item.merchantCustomerId,
                sortingField: 'merchantCustomerId',
            },
            {
                id: 'programRegionId',
                header: 'Program Region ID',
                cell: (item: StoreListRecord) => item.programRegionId,
                sortingField: 'programRegionId',
            },
            {
                id: 'fulfillmentType',
                header: 'Fulfillment Type',
                cell: (item: StoreListRecord) => item.fulfillmentType,
                sortingField: 'fulfillmentType',
            },
            {
                id: 'isActive',
                header: 'Is Active',
                cell: (item: StoreListRecord) => item.isActive,
                sortingField: 'isActive',
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: (item: StoreListRecord) => (
                    <Link href={`/store/${item.programRegionId}`} target="_blank" external={false} variant="primary">
                        View
                    </Link>
                ),
            },
        ],
        []
    );

    const overridesColumnDefinitions = useMemo(
        () => [
            {
                id: 'warehouseId',
                header: 'Warehouse ID',
                cell: (item: StoreListRecord) => item.warehouseId,
                sortingField: 'warehouseId',
            },
            {
                id: 'almPhysicalStoreId',
                header: 'ALM Physical Store ID',
                cell: (item: StoreListRecord) => item.almPhysicalStoreId,
                sortingField: 'almPhysicalStoreId',
            },
            {
                id: 'merchantCustomerId',
                header: 'Merchant Customer ID',
                cell: (item: StoreListRecord) => item.merchantCustomerId,
                sortingField: 'merchantCustomerId',
            },
            {
                id: 'programRegionId',
                header: 'Program Region ID',
                cell: (item: StoreListRecord) => item.programRegionId,
                sortingField: 'programRegionId',
            },
            {
                id: 'overrideType',
                header: 'Override Type',
                cell: (item) => item.overrideType,
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: (item) => (
                    <ButtonDropdown
                        items={[
                            {
                                id: 'view',
                                text: 'View Record',
                            },
                            {
                                id: 'remove',
                                text: 'Remove Override',
                            },
                        ]}
                        onItemClick={({detail}) => {
                            switch (detail.id) {
                                case 'view':
                                    setOverridesState((prevState) => ({
                                        ...prevState,
                                        selectedOverride: item,
                                        isRecordModalVisible: true,
                                    }));
                                    break;
                                case 'remove':
                                    setOverridesState((prevState) => ({
                                        ...prevState,
                                        overrideToDelete: item,
                                        isDeleteModalVisible: true,
                                    }));
                                    break;
                            }
                        }}
                        variant="normal"
                        expandToViewport={true}
                    >
                        Actions
                    </ButtonDropdown>
                ),
            },
        ],
        []
    );

    const handleNodeIdChange = useCallback(({detail}: {detail: {value: string}}) => {
        setState((prev) => ({...prev, nodeId: detail.value}));
    }, []);

    const handleKeyDown = useCallback(
        ({detail}: {detail: {key: string}}) => {
            if (detail.key === 'Enter') {
                handleSearch();
            }
        },
        [handleSearch]
    );

    const handleTabChange = useCallback(
        ({detail}) => {
            setActiveTabId(detail.activeTabId);
            if (detail.activeTabId === 'overrides') {
                fetchOverrides();
            }
        },
        [fetchOverrides]
    );

    return (
        <React.Fragment>
            <div className="main-header">
                <Header variant="h2">Metadata Dashboard</Header>
            </div>

            <div className="main-content">
                <SpaceBetween size="l">
                    <Tabs
                        activeTabId={activeTabId}
                        onChange={handleTabChange}
                        tabs={[
                            {
                                label: 'Stores',
                                id: 'stores',
                                content: (
                                    <SpaceBetween direction="vertical" size="l">
                                        <Container header={<Header variant="h2">Search By Node</Header>}>
                                            <SpaceBetween direction="horizontal" size="xs">
                                                <Input
                                                    data-testid="search-node-input"
                                                    value={state.nodeId}
                                                    onChange={handleNodeIdChange}
                                                    placeholder="Enter node ID"
                                                    onKeyDown={handleKeyDown}
                                                />
                                                <Button data-testid="search-button" onClick={handleSearch} loading={state.loading}>
                                                    Search
                                                </Button>
                                            </SpaceBetween>
                                        </Container>
                                        <Table
                                            columnDefinitions={storesColumnDefinitions}
                                            items={state.stores}
                                            loading={state.loading}
                                            loadingText="Loading stores"
                                            header={
                                                <Header
                                                    variant="h2"
                                                    counter={state.stores.length > 0 ? `(${state.stores.length})` : undefined}
                                                >
                                                    Results
                                                </Header>
                                            }
                                            empty={
                                                <Box textAlign="center" color="inherit">
                                                    <b>No stores found</b>
                                                    <Box padding={{bottom: 's'}}>
                                                        No stores to display. Try searching for a store.
                                                    </Box>
                                                </Box>
                                            }
                                            wrapLines
                                            stripedRows
                                        />
                                    </SpaceBetween>
                                ),
                            },
                            {
                                label: 'Active Overrides',
                                id: 'overrides',
                                content: (
                                    <Table
                                        columnDefinitions={overridesColumnDefinitions}
                                        items={overridesState.overrides}
                                        loading={!listMetadataOverrideResponse}
                                        loadingText="Loading overrides"
                                        header={
                                            <Header
                                                variant="h2"
                                                counter={
                                                    overridesState.overrides.length > 0
                                                        ? `(${overridesState.overrides.length})`
                                                        : undefined
                                                }
                                            >
                                                Overrides
                                            </Header>
                                        }
                                        empty={
                                            <Box textAlign="center" color="inherit">
                                                <b>No overrides found</b>
                                                <Box padding={{bottom: 's'}}>No overrides to display.</Box>
                                            </Box>
                                        }
                                        wrapLines
                                        stripedRows
                                    />
                                ),
                            },
                        ]}
                    />

                    <Modal
                        visible={overridesState.isRecordModalVisible}
                        onDismiss={() => {
                            setOverridesState((prevState) => ({
                                ...prevState,
                                isRecordModalVisible: false,
                                selectedOverride: null,
                            }));
                        }}
                        header={<Header variant="h2">Override Record Details</Header>}
                        size="large"
                    >
                        <SpaceBetween size="l">
                            <ColumnLayout columns={2} variant="text-grid">
                                {Object.entries(overridesState.selectedOverride || {}).map(([key, value]) => (
                                    <div key={key}>
                                        <Box variant="awsui-key-label">{key}</Box>
                                        <Box>{formatFieldValue(value)}</Box>
                                    </div>
                                ))}
                            </ColumnLayout>
                        </SpaceBetween>
                    </Modal>

                    <Modal
                        visible={overridesState.isDeleteModalVisible}
                        onDismiss={() => {
                            setOverridesState((prevState) => ({
                                ...prevState,
                                isDeleteModalVisible: false,
                                overrideToDelete: null,
                            }));
                        }}
                        header={<Header variant="h2">Confirm Removal</Header>}
                        footer={
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button
                                        variant="link"
                                        onClick={() => {
                                            setOverridesState((prevState) => ({
                                                ...prevState,
                                                isDeleteModalVisible: false,
                                            }));
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button variant="primary" onClick={handleRemoveOverride}>
                                        Confirm
                                    </Button>
                                </SpaceBetween>
                            </Box>
                        }
                    >
                        Are you sure you want to remove the override? This will revert this store metadata to upstream values.
                    </Modal>
                </SpaceBetween>
            </div>
        </React.Fragment>
    );
}
