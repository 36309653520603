import React, {useState} from 'react';
import {Box, Container, ContentLayout, Header, SpaceBetween, Table, Modal, ColumnLayout, Link} from '@amzn/awsui-components-react';
import {MetadataAuditRecords} from '@amzn/f3-excelsior-store-metadata-api/clients/apiforexcelsiorstoremetadata';
import {useParams} from 'react-router';
import {formatFieldValue} from './editors/metadata-record-edit-config';

interface AuditDetailPageProps {
    auditRecords: MetadataAuditRecords;
}

interface RecordViewModalProps {
    record: any;
    visible: boolean;
    onDismiss: () => void;
    timestamp: string;
}

export const RecordViewModal: React.FC<RecordViewModalProps> = ({record, visible, onDismiss, timestamp}) => {
    return (
        <Modal
            visible={visible}
            onDismiss={onDismiss}
            header={<Header variant="h2">Audit Record Details - {new Date(timestamp).toLocaleString()}</Header>}
            size="large"
        >
            <SpaceBetween size="l">
                <ColumnLayout columns={2} variant="text-grid">
                    {Object.entries(record || {}).map(([key, value]) => (
                        <div key={key}>
                            <Box variant="awsui-key-label">{key}</Box>
                            <Box>{formatFieldValue(value)}</Box>
                        </div>
                    ))}
                </ColumnLayout>
            </SpaceBetween>
        </Modal>
    );
};

export default function AuditDetailPage({auditRecords}: AuditDetailPageProps) {
    const {programRegionId} = useParams<{programRegionId: string}>();
    const [selectedRecord, setSelectedRecord] = useState<any>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const columnDefinitions = [
        {
            id: 'createdAt',
            header: 'Date',
            cell: (item: any) => new Date(item.createdAt).toLocaleString(),
            sortingField: 'createdAt',
        },
        {
            id: 'eventType',
            header: 'Event Type',
            cell: (item: any) => item.eventType,
            sortingField: 'eventType',
        },
        {
            id: 'user',
            header: 'User',
            cell: (item: any) => item.user,
            sortingField: 'user',
        },
        {
            id: 'record',
            header: 'Record',
            cell: (item: any) => (
                <Link
                    href="#"
                    onFollow={(e) => {
                        e.preventDefault();
                        setSelectedRecord(item);
                        setIsModalVisible(true);
                    }}
                >
                    View
                </Link>
            ),
        },
    ];

    return (
        <ContentLayout
            header={
                <Header variant="h1" description={`Program Region ID: ${programRegionId}`}>
                    Audit History
                </Header>
            }
        >
            <Container>
                <Table
                    columnDefinitions={columnDefinitions}
                    items={auditRecords}
                    loading={false}
                    loadingText="Loading audit records"
                    sortingDisabled={false}
                    variant="full-page"
                    empty={
                        <Box textAlign="center" color="inherit">
                            <b>No audit records</b>
                            <Box padding={{bottom: 's'}}>No audit records found</Box>
                        </Box>
                    }
                    header={<Header counter={`(${auditRecords.length})`}>Audit Records</Header>}
                />
            </Container>

            <RecordViewModal
                record={selectedRecord?.record}
                visible={isModalVisible}
                onDismiss={() => {
                    setIsModalVisible(false);
                    setSelectedRecord(null);
                }}
                timestamp={selectedRecord?.createdAt}
            />
        </ContentLayout>
    );
}
