import React, {useState, useMemo, useCallback} from 'react';
import {useParams} from 'react-router';
import {
    Box,
    ColumnLayout,
    Container,
    ContentLayout,
    Header,
    SpaceBetween,
    TextFilter,
    StatusIndicator,
    Toggle,
    Button,
    Modal,
    Table,
} from '@amzn/awsui-components-react';
import {MetadataRecord} from '@amzn/f3-excelsior-store-metadata-api/clients/apiforexcelsiorstoremetadata';
import {useRemoveMetadataOverride, useSubmitMetadataOverride} from '../hooks/use-store-metadata-api';
import {useAuth} from '../hooks/use-auth';
import {translateErrorToReactNode} from '../common';
import {FIELD_CONFIGS, formatFieldName, formatFieldValue} from './editors/metadata-record-edit-config';
import {Flashbar} from '@amzn/awsui-components-react';
import {EditableMetadataField} from './editable-metadata-field';

interface StoreMetadataPageProps {
    pushNotification: any;
    metadataRecord: MetadataRecord;
    clientConfiguration: any;
    isOverriden?: boolean;
}

export default function StoreMetadataPage({
    pushNotification,
    metadataRecord,
    clientConfiguration,
    isOverriden,
}: StoreMetadataPageProps) {
    const auth = useAuth();
    const {programRegionId} = useParams<{programRegionId: string}>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [filteringText, setFilteringText] = useState('');
    const [confirmationAction, setConfirmationAction] = useState<'submit' | 'remove' | 'delete' | null>(null);
    const [editState, setEditState] = useState({
        isEditing: false,
        hasUnsavedChanges: false,
        editedData: metadataRecord,
    });

    const changedFields = useMemo(() => {
        if (!metadataRecord || !editState.editedData) return [];

        return Object.entries(editState.editedData)
            .filter(([key, value]) => {
                const originalValue = metadataRecord[key];
                return value !== originalValue;
            })
            .map(([key, newValue]) => ({
                fieldName: FIELD_CONFIGS[key]?.label || formatFieldName(key),
                originalValue: formatFieldValue(metadataRecord[key]),
                newValue: formatFieldValue(newValue),
            }));
    }, [metadataRecord, editState.editedData]);

    const createErrorListener = (header: string) => (error: Error) => {
        setError(error);
        pushNotification({
            type: 'error',
            content: translateErrorToReactNode(error),
            header,
        });
    };

    const {execute: executeUseSubmitMetadataOverride} = useSubmitMetadataOverride(
        clientConfiguration,
        createErrorListener('SubmitMetadataOverride failed'),
        [auth]
    );

    const {execute: executeUseRemoveMetadataOverride} = useRemoveMetadataOverride(
        clientConfiguration,
        createErrorListener('RemoveMetadataOverride failed'),
        [auth]
    );

    const handleConfirmAction = useCallback(async () => {
        setIsSubmitting(true);
        try {
            if (confirmationAction === 'submit') {
                await executeUseSubmitMetadataOverride({
                    programRegionId: programRegionId,
                    overrideType: 'UPDATE',
                    record: editState.editedData!,
                });
                setEditState({
                    ...editState,
                    isEditing: false,
                    hasUnsavedChanges: false,
                });
                pushNotification({
                    type: 'success',
                    content: 'Update Override has been submitted.',
                });
            } else if (confirmationAction === 'remove') {
                await executeUseRemoveMetadataOverride({programRegionId: programRegionId});
                pushNotification({
                    type: 'success',
                    content: 'Override removal has been submitted.',
                });
            } else if (confirmationAction === 'delete') {
                await executeUseSubmitMetadataOverride({
                    programRegionId: programRegionId,
                    overrideType: 'DELETE',
                    record: metadataRecord,
                });
                setEditState({
                    ...editState,
                    isEditing: false,
                    hasUnsavedChanges: false,
                });
                pushNotification({
                    type: 'success',
                    content: 'Delete Override has been submitted.',
                });
            }
        } catch (error) {
            pushNotification({
                type: 'error',
                content: `Failed to ${confirmationAction} override.`,
            });
        } finally {
            setIsSubmitting(false);
            setConfirmationAction(null);
        }
    }, [
        confirmationAction,
        executeUseSubmitMetadataOverride,
        executeUseRemoveMetadataOverride,
        programRegionId,
        editState,
        pushNotification,
    ]);

    const handleSaveClick = useCallback(() => {
        setConfirmationAction('submit');
    }, []);

    const handleRemoveOverrideClick = useCallback(() => {
        setConfirmationAction('remove');
    }, []);

    const handleDeleteOverrideClick = useCallback(() => {
        setConfirmationAction('delete');
    }, []);

    const confirmationModal = (
        <Modal
            visible={confirmationAction !== null}
            onDismiss={() => setConfirmationAction(null)}
            header={
                confirmationAction === 'submit'
                    ? 'Confirm Changes'
                    : confirmationAction === 'remove'
                    ? 'Confirm Remove Override'
                    : 'Confirm Delete Override'
            }
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => setConfirmationAction(null)} disabled={isSubmitting}>
                            Cancel
                        </Button>
                        <Button
                            data-testid="confirm-action-button"
                            variant="primary"
                            onClick={handleConfirmAction}
                            loading={isSubmitting}
                            disabled={isSubmitting}
                        >
                            {confirmationAction === 'submit'
                                ? 'Confirm'
                                : confirmationAction === 'remove'
                                ? 'Remove Override'
                                : 'Confirm'}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            {confirmationAction === 'submit' ? (
                <SpaceBetween size="m">
                    <Box>Please review the following changes:</Box>
                    <Table
                        columnDefinitions={[
                            {
                                id: 'fieldName',
                                header: 'Field',
                                cell: (item) => item.fieldName,
                            },
                            {
                                id: 'originalValue',
                                header: 'Original Value',
                                cell: (item) => item.originalValue,
                            },
                            {
                                id: 'newValue',
                                header: 'New Value',
                                cell: (item) => item.newValue,
                            },
                        ]}
                        items={changedFields}
                        loadingText="Loading changes"
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>No changes</b>
                                <Box padding={{bottom: 's'}}>No fields have been modified</Box>
                            </Box>
                        }
                    />
                </SpaceBetween>
            ) : confirmationAction === 'remove' ? (
                <Box>Are you sure you want to remove the override? This will revert this store metadata to upstream values.</Box>
            ) : (
                <Box>
                    Are you sure you want to submit a DELETE override for this store? This will remove the store from the metadata
                    table.
                </Box>
            )}
        </Modal>
    );

    const handleEditModeChange = useCallback(
        (enabled: boolean) => {
            if (!enabled && editState.hasUnsavedChanges) {
                if (window.confirm('You have unsaved changes. Are you sure you want to exit edit mode?')) {
                    setEditState({
                        isEditing: false,
                        hasUnsavedChanges: false,
                        editedData: metadataRecord,
                    });
                }
                return;
            }

            setEditState((prev) => ({
                ...prev,
                isEditing: enabled,
                editedData: enabled ? prev.editedData : metadataRecord,
            }));
        },
        [editState.hasUnsavedChanges, metadataRecord]
    );

    const filteredProperties = useMemo(() => {
        if (!metadataRecord) return [];
        return Object.entries(metadataRecord).filter(
            ([key, value]) =>
                key.toLowerCase().includes(filteringText.toLowerCase()) ||
                String(value).toLowerCase().includes(filteringText.toLowerCase())
        );
    }, [metadataRecord, filteringText]);

    const headerActions = (
        <SpaceBetween direction="horizontal" size="xs" alignItems="center">
            <Toggle
                data-testid="edit-toggle"
                onChange={({detail}) => handleEditModeChange(detail.checked)}
                checked={editState.isEditing}
            >
                Edit mode
            </Toggle>
            {editState.isEditing && (
                <>
                    {editState.hasUnsavedChanges && (
                        <Button data-testid="save-changes-button" variant="primary" onClick={handleSaveClick}>
                            Save changes
                        </Button>
                    )}
                    <Button data-testid="delete-override-button" variant="normal" onClick={handleDeleteOverrideClick}>
                        Delete Store
                    </Button>
                </>
            )}
        </SpaceBetween>
    );

    const handleValueChange = useCallback((key: string, value: any) => {
        setEditState((prev) => ({
            ...prev,
            editedData: {
                ...prev.editedData!,
                [key]: value,
            },
            hasUnsavedChanges: true,
        }));
    }, []);

    if (error || !metadataRecord) {
        return (
            <Box margin="xxl" textAlign="center">
                <StatusIndicator type="error">Failed to load metadata: {error?.message || 'Store Not Found'}</StatusIndicator>
            </Box>
        );
    }

    return (
        <ContentLayout
            header={
                <SpaceBetween size="xs">
                    {isOverriden && (
                        <Flashbar
                            items={[
                                {
                                    type: 'info',
                                    content: "This store's metadata is currently overriden. View Audit History to learn more.",
                                    buttonText: 'Remove Override',
                                    onButtonClick: handleRemoveOverrideClick,
                                    loading: isSubmitting,
                                    dismissible: false,
                                    statusIconAriaLabel: 'Info',
                                },
                            ]}
                        />
                    )}
                    <Header variant="h1" actions={headerActions}>
                        {metadataRecord.warehouseId} - {metadataRecord.programRegionId}
                    </Header>
                    <TextFilter
                        filteringText={filteringText}
                        onChange={({detail}) => setFilteringText(detail.filteringText)}
                        countText={`${filteredProperties.length} properties`}
                        filteringPlaceholder="Find property"
                        filteringAriaLabel="Filter properties"
                    />
                </SpaceBetween>
            }
        >
            <Container
                header={
                    <Header variant="h2" counter={`(${filteredProperties.length})`}>
                        Properties
                    </Header>
                }
            >
                <ColumnLayout columns={2} variant="text-grid">
                    {filteredProperties.map(([key, value]) => (
                        <EditableMetadataField
                            key={key}
                            label={formatFieldName(key)}
                            value={editState.isEditing ? editState.editedData?.[key] : formatFieldValue(value)}
                            isEditing={editState.isEditing}
                            onValueChange={(newValue) => handleValueChange(key, newValue)}
                            fieldConfig={FIELD_CONFIGS[key] || {type: 'text', editable: false, label: formatFieldName(key)}}
                        />
                    ))}
                </ColumnLayout>
            </Container>
            {confirmationModal}
        </ContentLayout>
    );
}
