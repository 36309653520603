import React, {useMemo} from 'react';
import {Alert, Box, Tabs} from '@amzn/awsui-components-react';
import {useAuth} from '../hooks/use-auth';
import {Notification} from '../navigation/page-layout';
import {MerchantAutomation} from '../merchant-automation';
import ListStoresPage from '../metadata/list-stores';

export default function Stores(props: {pushNotification: (notification: Notification) => void}) {
    const auth = useAuth();
    const globalScopes = auth.authInformation!.globalScopes!;

    const hasMetadataAccess = useMemo(
        () => globalScopes['metadata']?.split(',').includes('view') ?? false,
        [auth.authInformation?.globalScopes]
    );

    const hasMerchantsAccess = useMemo(
        () => globalScopes['merchants']?.split(',').includes('view') ?? false,
        [auth.authInformation?.globalScopes]
    );

    const Unauthorized = () => (
        <Box margin={{top: 'l'}}>
            <Alert type="error" header="Unauthorized Access">
                You do not have the required permissions to access this content.
            </Alert>
        </Box>
    );

    const storesPageTabs = useMemo(
        () => [
            {
                label: 'Metadata',
                id: 'metadata',
                content: hasMetadataAccess ? <ListStoresPage pushNotification={props.pushNotification} /> : <Unauthorized />,
            },
            {
                label: 'IUDF',
                id: 'iudf',
                content: hasMerchantsAccess ? <MerchantAutomation pushNotification={props.pushNotification} /> : <Unauthorized />,
            },
        ],
        [hasMetadataAccess, hasMerchantsAccess, props.pushNotification]
    );

    return (
        <React.Fragment>
            <Tabs tabs={storesPageTabs} />
        </React.Fragment>
    );
}
